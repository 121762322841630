<template>
  <!-- <dv-border-box-8 v-if="false" :color="['', '#6a6a6a']" :dur="4">
    <slot></slot>
  </dv-border-box-8> -->
  <dv-border-box-12 :color="['#003b51', '#109eb5']" class="border-static">
    <slot></slot>
  </dv-border-box-12>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: () => true
    }
  }
}

</script>

<style lang="scss" scope>
.border-static {
  // border: 1px solid #215595;
  // width: calc(100% - 5px);
  // margin-left: -1px;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
